/* eslint-disable no-use-before-define */
import { DisconnectedError } from 'DisconnectedError';
import { StudentDashboardContentProvider } from 'StoredContent';
import { shoveProgressIntoContent } from 'LessonProgress';
import { getCohort } from 'Users';
import cohortKeysForStudentDashboardFetch from 'FrontRoyalStore/cohortKeysForStudentDashboardFetch';
import { SuspendableResource } from 'useSuspendableResource';
import angularModule from './learner_content_cache_module';

angularModule.factory('LearnerContentCache', [
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const studentDashboardContentProvider = new StudentDashboardContentProvider($injector);
        const ConfigFactory = $injector.get('ConfigFactory');

        const LearnerContentCache = {
            async ensureStudentDashboard() {
                const user = $rootScope.currentUser;
                if (!user) {
                    throw new Error('No user found');
                }

                const promise = ConfigFactory.getConfig().then(config =>
                    studentDashboardContentProvider.getStudentDashboardContent({
                        userId: user.id,
                        prefLocale: user.pref_locale,
                        contentViewsRefreshUpdatedAt: config.contentViewsRefreshUpdatedAt(),
                        favoriteLessonStreamLocalePackIds: user.favorite_lesson_stream_locale_packs.map(lp => lp.id),
                        ...cohortKeysForStudentDashboardFetch(getCohort(user)),
                    }),
                );

                return promise.then(async response => {
                    // If the current user changed while content was loading, do not attempt
                    // to store the student dashboard or shove progress on.
                    // See https://trello.com/c/hFv8MgQx, issue with `t.currentUser.progress`
                    if (!user || user !== $rootScope.currentUser) return response;

                    // FIXME: Once all users have access to the store, we can stop shoving progress into content
                    // and rely on the store instead (see https://trello.com/c/44m4boVb).
                    await shoveProgressIntoContent(response.result[0].lesson_streams, user);
                    return response;
                });
            },

            async preloadStudentDashboard() {
                return this.ensureStudentDashboard().catch(err => {
                    // ensureStudentDashboard can throw a DisconnectedError if
                    // we enter offline mode while it is in flight.  Since preloadStudentDashboard
                    // is just a fire-and-forget call, we can ignore that error.
                    if (err.constructor !== DisconnectedError) {
                        throw err;
                    }
                });
            },

            bustCache() {
                studentDashboardContentProvider.bustCache();
            },
        };

        LearnerContentCache.suspendableResource = new SuspendableResource(LearnerContentCache.ensureStudentDashboard);
        $rootScope.$on('$destroy', () => {
            LearnerContentCache.suspendableResource.destroy();
        });

        return LearnerContentCache;
    },
]);
