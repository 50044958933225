import { useSuspendableResource } from 'useSuspendableResource';
import { useLearnerContentCache } from './useLearnerContentCache';

// Right now it's implied that this hook is for the current User's student dashboard content.
// That implication only exists because LearnerContentCache.ensureStudentDashboard uses Angular.JS's currentUser.
// When we get rid of LearnerContentCache entirely, we'll want to `useCurrentUser` here.
export function useStudentDashboardContent() {
    const LearnerContentCache = useLearnerContentCache();
    return useSuspendableResource(LearnerContentCache.suspendableResource);
}
