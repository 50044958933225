import { memo } from 'react';
import { type ClickableTextProps, type HasHrefNotOnPress, ClickableText } from 'ClickableText';

// We do not include ValidWrapProps because inline links are always configured to wrap. For that
// reason, they cannot support icons. See comment near AllowWrapProps for an explanation of why
// those are connected.
type InlineLinkProps = HasHrefNotOnPress & Pick<ClickableTextProps, 'children' | 'color' | 'className'>;

function InlineLinkComponent(props: InlineLinkProps) {
    const { children, ...rest } = props;
    return (
        <ClickableText allowWrap {...rest}>
            {children}
        </ClickableText>
    );
}

export const InlineLink = memo(InlineLinkComponent) as typeof InlineLinkComponent;
