import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import template from 'Lessons/angularModule/views/lesson/show_frames_player.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import getFilePathsFromManifest from 'WebpackManifestHelper';
import { storeProvider } from 'ReduxHelpers';
import { chatActions } from 'TutorBotChat/redux/chat';
import { getFrameContainerSizeClasses } from 'Lessons';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { AiAdvisorTooltip } from 'TutorBotAiTutorChat/AiAdvisorTooltip/AiAdvisorTooltip';
import { StartScreenButtonWithTooltip } from 'TutorBotAiTutorChat/StartScreenButtonWithTooltip/StartScreenButtonWithTooltip';
import { statsigClientProvider } from 'StatsigClientProvider';

angularModule.component(
    'aiAdvisorTooltip',
    react2Angular(AiAdvisorTooltip, [
        'backwardsClass',
        'continueButtonVisible',
        'buttonKey',
        'launchBot',
        'allowOpen',
        'onClose',
    ]),
);

angularModule.component(
    'startScreenButtonWithTooltip',
    react2Angular(StartScreenButtonWithTooltip, [
        'onClose',
        'onIntentionalDismiss',
        'onButtonClick',
        'allowOpen',
        'launchBot',
        'isTest',
        'isAssessment',
        'lessonSupportsRecap',
    ]),
);

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('showFramesPlayer', [
    '$injector',
    function factory($injector) {
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const scopeTimeout = $injector.get('scopeTimeout');
        const $ocLazyLoad = $injector.get('$ocLazyLoad');
        const $timeout = $injector.get('$timeout');
        const $rootScope = $injector.get('$rootScope');
        const MessagingService = $injector.get('Lesson.MessagingService');
        const $window = $injector.get('$window');
        const safeApply = $injector.get('safeApply');
        const EventLogger = $injector.get('EventLogger');

        return {
            restrict: 'E',
            templateUrl,
            link(scope, element) {
                AppHeaderViewModel.showAlternateHomeButton = true;
                scope.animate = () => element.data('$$ngAnimateState');
                scope.MessagingService = MessagingService;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                /*
                        indicates what should currently be on the screen.  Can be
                        frame, startScreen, finishScreen.  Is set to
                        undefined momentarily when transitioning from one to the next
                        in order to allow the animation.
                    */
                Object.defineProperty(scope, 'show', {
                    get() {
                        return scope.$show;
                    },
                    set(val) {
                        if (val === scope.$show) {
                            return;
                        }

                        scope.$show = val;
                    },
                });

                scope.backwardsClass = '';

                Object.defineProperty(scope, 'frameDirectiveName', {
                    get() {
                        if (this.show !== 'frame') {
                            return undefined;
                        }
                        return this.activeFrameViewModel && this.activeFrameViewModel.frame.directiveName;
                    },
                });

                Object.defineProperty(scope, 'buttonDirectiveName', {
                    get() {
                        if (this.show !== 'frame') {
                            return undefined;
                        }
                        return this.activeFrameViewModel && this.activeFrameViewModel.frame.buttonDirectiveName;
                    },
                });

                Object.defineProperty(scope, 'supportsRecapLastLesson', {
                    get() {
                        return !!scope.playerViewModel.preLessonReviewMessage;
                    },
                });

                scope.onContinueButtonClick = show => {
                    if (show) scope.onTooltipClose();
                };

                scope.handleStartScreenButtonClick = () => {
                    scope.playerViewModel.showStartScreen = false;
                };

                Object.defineProperty(scope, 'continueButtonVisible', {
                    get() {
                        return !!this.buttonDirectiveName && this.activeFrameViewModel.continueButtonVisible;
                    },
                });

                Object.defineProperty(scope, 'showingEndColumn', {
                    get() {
                        return scope.playerViewModel.showingBot;
                    },
                    configurable: true,
                });

                scope.includedInAiAdvisorTooltipTest = false;
                scope.includedInAudioRecapLesson = false;
                statsigClientProvider.waitForGate('show_ai_advisor_tooltip').then(result => {
                    scope.includedInAiAdvisorTooltipTest = result;
                });
                statsigClientProvider.waitForGate('audio_recap_lesson').then(result => {
                    scope.includedInAudioRecapLesson = result;
                });

                scope.$watchGroup(
                    [
                        'includedInAiAdvisorTooltipTest',
                        'currentUser.has_seen_ai_advisor_tooltip',
                        'activeFrameViewModel.mainUiComponentViewModel.componentName',
                        'playerViewModel.botLaunchOptions.messageContent',
                    ],
                    () => {
                        scope.showAiAdvisorTooltip = false;

                        if (!scope.includedInAiAdvisorTooltipTest) return;
                        if (scope.currentUser?.has_seen_ai_advisor_tooltip) return;
                        if (scope.activeFrameViewModel?.mainUiComponentViewModel.componentName !== 'Challenges') {
                            return;
                        }
                        if (scope.playerViewModel?.botLaunchOptions?.messageContent !== 'EXPLAIN_SCREEN') return;

                        scope.showAiAdvisorTooltip = true;
                    },
                );

                // The recap tooltip is available on the start screen and also on the first frame
                // if it's a recap-enabled lesson. This handles the start screen case.
                scope.$watchGroup(
                    [
                        'includedInAudioRecapLesson',
                        'currentUser.has_seen_audio_recap_lesson_tooltip',
                        'playerViewModel.showStartScreen',
                        'supportsRecapLastLesson',
                    ],
                    () => {
                        scope.showStartScreenTooltip = false;

                        if (!scope.supportsRecapLastLesson) return;
                        if (!scope.includedInAudioRecapLesson) return;
                        if (scope.currentUser?.has_seen_audio_recap_lesson_tooltip) return;
                        if (!scope.playerViewModel.showStartScreen) return;
                        // The bot is not available for exams or assessments
                        if (scope.playerViewModel.test || scope.playerViewModel.assessment) return;

                        scope.showStartScreenTooltip = true;
                    },
                );

                // The recap tooltip is available on the start screen and also on the first frame
                // if it's a recap-enabled lesson. This handles the frame case.
                scope.$watchGroup(
                    [
                        'includedInAudioRecapLesson',
                        'currentUser.has_seen_audio_recap_lesson_tooltip',
                        'playerViewModel.botLaunchOptions.buttonKey',
                        'supportsRecapLastLesson',
                    ],
                    () => {
                        scope.showRecapTooltip = false;

                        if (!scope.supportsRecapLastLesson) return;
                        if (!scope.includedInAudioRecapLesson) return;
                        if (scope.currentUser.has_seen_audio_recap_lesson_tooltip) return;
                        if (scope.playerViewModel.botLaunchOptions.buttonKey !== 'recap_last_lesson') return;

                        scope.showRecapTooltip = true;
                    },
                );

                scope.$watchGroup(
                    ['showAiAdvisorTooltip', 'showRecapTooltip', 'activeFrameViewModel.index'],
                    (newVals, oldVals) => {
                        const [showAiAdvisorTooltip, showRecapTooltip, index] = newVals;
                        const [prevShowAiAdvisorTooltip, prevShowRecapTooltip, prevIndex] = oldVals;

                        // handle changing frames that are not done via the continue button
                        if (prevIndex === index) return;
                        if (!prevShowAiAdvisorTooltip && showAiAdvisorTooltip) return;
                        if (!prevShowRecapTooltip && showRecapTooltip) return;
                        if (!prevShowAiAdvisorTooltip && !showAiAdvisorTooltip) return;
                        if (!prevShowRecapTooltip && !showRecapTooltip) return;

                        scope.onTooltipClose();
                    },
                );

                scope.onTooltipClose = () => {
                    const userFlag =
                        scope.playerViewModel.botLaunchOptions.buttonKey === 'recap_last_lesson'
                            ? 'has_seen_audio_recap_lesson_tooltip'
                            : 'has_seen_ai_advisor_tooltip';
                    scope.currentUser[userFlag] = true;
                    if (scope.currentUser.ghostMode) return;
                    scope.currentUser.save();
                };

                scope.onStartScreenTooltipClose = () => {
                    scope.playerViewModel.showStartScreenTooltip = false;
                };

                scope.onStartScreenTooltipIntentionalDismiss = () => {
                    scope.currentUser.has_seen_audio_recap_lesson_tooltip = true;
                    if (scope.currentUser.ghostMode) return;
                    EventLogger.log('tutorbot:dismissed_audio_recap_lesson_tooltip', {});
                    scope.currentUser.save();
                };

                const launchBot = eventType => {
                    EventLogger.log(eventType, {});
                    scope.playerViewModel.launchBot();
                    // The scope.apply that normally is triggered by ng-click
                    // won't happen when the function is called in a react component
                    // so we need to use safeApply to ensure the bot slides in quickly
                    // instead of waiting for another digest cycle to occur
                    safeApply(scope);
                };

                scope.handleStartScreenLaunchBot = () => {
                    scope.onStartScreenTooltipIntentionalDismiss();
                    launchBot('tutorbot:lesson_start_screen_launch_bot_clicked');
                };

                scope.handleAiAdvisorTooltipLaunchBot = () => {
                    launchBot('tutorbot:lesson_frame_launch_bot_clicked');
                };

                function resetConversation() {
                    storeProvider.store.dispatch(chatActions.reset());
                }
                scope.$on('$destroy', () => {
                    resetConversation();
                    if (scope.showAiAdvisorTooltip || scope.showRecapTooltip) {
                        scope.onTooltipClose();
                    }
                    if (scope.showStartScreenTooltip) {
                        scope.onStartScreenTooltipClose();
                    }
                });

                function preloadAssessmentEndScreenStyles() {
                    if (scope.playerViewModel.assessment) {
                        getFilePathsFromManifest('assessment', 'css').forEach(path => {
                            $ocLazyLoad.load(path);
                        });
                    }
                }

                function updateAppHeader(val) {
                    const origColor = AppHeaderViewModel.bodyBackgroundColor;

                    if (val === 'frame') {
                        scope.activeFrameViewModel.setBackgroundColor();
                        AppHeaderViewModel.showMobileMessages = true;
                    } else {
                        let bgColor = val === 'finishScreen' ? 'beige-pattern' : 'white';
                        // special hack for demo mode: use a different background
                        if ((val === 'finishScreen' || val === 'startScreen') && scope.playerViewModel.demoMode) {
                            bgColor = 'demo-pattern';
                        }
                        scope.playerViewModel.setBodyBackground(bgColor);
                        AppHeaderViewModel.showMobileMessages = false;
                    }

                    const newColor = AppHeaderViewModel.bodyBackgroundColor;

                    AppHeaderViewModel.showFrameInstructions = !!val;

                    // the background color change is not animated,
                    // because animating background-image changes
                    // does not work well (I've tried it).  The change in
                    // the app header color is animated, though, so
                    // delay half a second
                    if (newColor !== origColor) {
                        return scopeTimeout(scope, 500);
                    }
                    return undefined;
                }

                function transition(action, delayBeforeRemovingContent, delayBeforeSettingNewContent) {
                    // Even if delayBeforeRemovingContent is 0, we want a timeout here,
                    // because, since the continue button is wrapped inside of a transcluded element,
                    // we need to add the backwards class first, and then wait a tick before kicking
                    // off the ng-if
                    let newShowValue;
                    let canceled = false;
                    scope.lastTransition = {
                        cancel() {
                            canceled = true;
                        },
                    };
                    scopeTimeout(scope, delayBeforeRemovingContent)
                        .then(() => {
                            if (canceled || scope.playerViewModel.destroyed) {
                                return undefined;
                            }
                            // In desktop, we don't want to hide the message right away,
                            // because we want it to slide off the screen along with the frame.
                            AppHeaderViewModel.showMobileMessages = false;

                            scope.show = undefined;
                            return scopeTimeout(scope, delayBeforeSettingNewContent);
                        })
                        .then(() => {
                            if (canceled || scope.playerViewModel.destroyed) {
                                return undefined;
                            }
                            newShowValue = action();

                            return updateAppHeader(newShowValue);
                        })
                        .then(() => {
                            if (canceled || scope.playerViewModel.destroyed) {
                                return;
                            }

                            scope.show = newShowValue;

                            // Reset any vertical scroll that might have occured
                            $injector.get('scrollHelper').scrollToTop();

                            // now that we've succeeded in getting something on the screen,
                            // go ahead and prep any assessment end screen styles
                            preloadAssessmentEndScreenStyles();
                        })
                        .catch(err => {
                            // if timeout has been cancelled (do to destroy) before transition completes
                            // this will suppress (useful for specs)
                            if (err !== 'canceled') {
                                throw err;
                            }
                        });
                }

                scope.onFrameVisible = elemScope => {
                    if (elemScope) {
                        elemScope.$emit('frame_visible');
                        elemScope.$broadcast('frame_visible');
                    }
                };

                let previousFrameIndex = null;

                // When the user is navigating backwards, we want to add the backwards class. Since the player view model
                // will set the activeFrame to undefined for a moment while transitioning, we need to pay attention to the
                // targetFrame.
                scope.$watchGroup(
                    [
                        'playerViewModel.targetFrame',
                        'playerViewModel.activeFrame',
                        'playerViewModel.showFinishScreen',
                        'playerViewModel.showStartScreen',
                    ],
                    () => {
                        const targetFrame = scope.playerViewModel.targetFrame;
                        let activeFrameIndex;
                        if (scope.playerViewModel.showStartScreen) {
                            activeFrameIndex = -1;
                        } else if (scope.playerViewModel.showFinishScreen) {
                            activeFrameIndex = Infinity;
                        } else {
                            activeFrameIndex = scope.playerViewModel.activeFrame?.index();
                        }

                        if (previousFrameIndex == null) {
                            scope.backwardsClass = '';
                        } else if (targetFrame) {
                            scope.backwardsClass = targetFrame?.index() < previousFrameIndex ? 'backwards' : '';
                        } else if (activeFrameIndex != null) {
                            scope.backwardsClass = activeFrameIndex < previousFrameIndex ? 'backwards' : '';
                        }

                        if (activeFrameIndex != null) previousFrameIndex = activeFrameIndex;
                    },
                );

                // NOTE: with watchGroup, the second argument is not really what you think it would be.  It does not show the old values
                // of each of these things the past time this watch was run.  This seems to be working anyhow though, so I'm not gonna
                // mess with it.
                scope.$watchGroup(
                    [
                        'playerViewModel.activeFrameViewModel',
                        'playerViewModel.showStartScreen',
                        'playerViewModel.showFinishScreen',
                    ],
                    (newStuff, oldStuff) => {
                        scope.cancelSlowLoadTimeouts();
                        const newFrameViewModel = newStuff[0];
                        const oldFrameViewModel = oldStuff[0];

                        let delayBeforeRemovingContent;
                        let delayBeforeSettingNewContent;
                        // For the bookends, we are hacking around ng-animate, so the
                        // animation happens before scope.show is set to undefined.  The
                        // animation lasts for 1 second

                        if (['startScreen', 'finishScreen'].includes(scope.show)) {
                            delayBeforeRemovingContent = 500;
                            delayBeforeSettingNewContent = 0;
                        }
                        // not a first page load
                        // For frames the transition lasts 500ms, after scope.show is set to undefined
                        else if (scope.$show) {
                            delayBeforeRemovingContent = 0;
                            delayBeforeSettingNewContent = 500;
                        }
                        // a first page load, not transitioning in from anything, so no need to delay
                        // before animations starts
                        else {
                            delayBeforeRemovingContent = 0;
                            delayBeforeSettingNewContent = 0;
                        }

                        if (scope.lastTransition) {
                            scope.lastTransition.cancel();
                        }
                        transition(
                            () => {
                                scope.cancelSlowLoadTimeouts();

                                // once the old frame is off the screen, clear messages, set background styles,
                                // and set the new frame and triggering its animation onto the screen
                                if (oldFrameViewModel && oldFrameViewModel.playerViewModel) {
                                    oldFrameViewModel.playerViewModel.clearMessage();
                                }

                                // If we're putting a new frame on the screen, clear messages as well,
                                // just in case the old frame never finished transitioning off.
                                // See https://trello.com/c/FvrAjwim/1033-bug-answer-messages-comes-back-after-branching-frame-related-to-practice-refactor#
                                if (newFrameViewModel && newFrameViewModel.playerViewModel) {
                                    newFrameViewModel.playerViewModel.clearMessage();
                                }

                                let targetShowValue;

                                scope.activeFrameViewModel = undefined;
                                if (scope.playerViewModel.showStartScreen) {
                                    targetShowValue = 'startScreen';
                                } else if (scope.playerViewModel.showFinishScreen) {
                                    targetShowValue = 'finishScreen';
                                } else if (newFrameViewModel) {
                                    scope.activeFrameViewModel = newFrameViewModel;
                                    targetShowValue = 'frame';
                                } else {
                                    scope.slowLoadTimeouts = [
                                        $timeout(scope.showSlowLoadingMessaging, 10 * 1000),
                                        $timeout(scope.showSlowLoadingExitButton, 20 * 1000),
                                    ];
                                    // targetShowValue remains undefined
                                }
                                return targetShowValue;
                            },
                            delayBeforeRemovingContent,
                            delayBeforeSettingNewContent,
                        );
                    },
                );

                scope.cancelSlowLoadTimeouts = () => {
                    scope.showSlowLoadingMessage = false;
                    scope.showExitButtonOnSlowLoading = false;
                    if (scope.slowLoadTimeouts) {
                        scope.slowLoadTimeouts.forEach(promise => {
                            $timeout.cancel(promise);
                        });
                    }
                };

                function logSlowLoadingEvent(eventName) {
                    scope.playerViewModel.log(eventName);
                }

                scope.showSlowLoadingMessaging = () => {
                    logSlowLoadingEvent('lesson:show_slow_loading_message');
                    scope.showSlowLoadingMessage = true;
                };

                scope.showSlowLoadingExitButton = () => {
                    logSlowLoadingEvent('lesson:show_slow_loading_exit');
                    scope.showExitButtonOnSlowLoading = true;
                };

                scope.exit = () => {
                    logSlowLoadingEvent('lesson:slow_loading_exit_clicked');
                    AppHeaderViewModel.exitButtonClick();
                };

                // stop propagation of the click event as well as the mousedown
                // and touchstart events, which are handled by provideHint below
                scope.stopPropagation = evt => {
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                };

                scope.$watch('playerViewModel', playerViewModel => {
                    playerViewModel.setBodyBackground('white');
                });

                scope.$watch('playerViewModel', playerViewModel => {
                    playerViewModel.started = true;
                });

                function setFrameContainerClasses() {
                    scope.frameContainerClasses = getFrameContainerSizeClasses(element.find('.main-column').width());

                    if (['finishScreen', 'startScreen'].includes(scope.show)) {
                        scope.frameContainerClasses.push('showing-bookend-screen');
                    }
                }

                function handleAppHeaderVisibility() {
                    // Hide app header so that the user doesn't accidentally click the app header Back button
                    // to exit the split screen, which has its own exit button.
                    AppHeaderViewModel.toggleVisibility(!scope.showingEndColumn);
                }

                function onFrameContainerResize() {
                    setFrameContainerClasses();
                    handleAppHeaderVisibility();
                    scope.$broadcast('frame_container_resized');
                }

                scope.$watch('showingEndColumn', () => {
                    // there is a transition after showingEndColumn, so call setFrameContainerClasses a few times
                    // to make sure we get it called after things have settled down
                    const numberOfUpdates = 10;
                    const updateInterval = 50;
                    [...Array(numberOfUpdates).keys()].forEach(i => {
                        $timeout(i * updateInterval).then(onFrameContainerResize);
                    });
                });

                scope.$watch('show', onFrameContainerResize);

                $($window).on('resize.show-frames-player', () => {
                    onFrameContainerResize();
                    safeApply(scope);
                });

                function onDestroy() {
                    if (scope.playerViewModel) {
                        scope.playerViewModel.destroy();
                    }
                    scope.cancelSlowLoadTimeouts();
                    $($window).off('resize.show-frames-player');
                    AppHeaderViewModel.toggleVisibility(true);
                }
                // We used to do this only on scope.$destroy, but then the event would get fired
                // after navigating to a different route when a user clicked the exit button.
                // This was not a big deal, but led to unexpected 'directive' property in the
                // frame:unload event.  See https://trello.com/c/9FFXCTfA/432-chore-investigate-unexpected-directive-notifications
                $rootScope.$on('$routeChangeStart', onDestroy);
                scope.$on('$destroy', onDestroy);
            },
        };
    },
]);
