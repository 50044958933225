import { Route } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';
import { ProtectedRoute } from 'ProtectedRoute';

export const ROUTES = {
    CHOOSE_INSTITUTION: route('choose-institution', {}),
};

async function getLazyChooseInstitutionComponent(component: 'ChooseInstitutionPage') {
    const components = await import(/* webpackPrefetch: true */ './components/ChooseInstitutionPage');
    const ChooseInstitutionComponent = components[component];
    if (component === 'ChooseInstitutionPage') {
        return {
            Component: () => (
                <ProtectedRoute>
                    <ChooseInstitutionComponent />
                </ProtectedRoute>
            ),
        };
    }
    return { Component: ChooseInstitutionComponent };
}

export function getChooseInstitutionRoutes() {
    return (
        <Route
            path={ROUTES.CHOOSE_INSTITUTION.relativePath}
            lazy={() => getLazyChooseInstitutionComponent('ChooseInstitutionPage')}
        />
    );
}
